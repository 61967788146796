<template>
  <div class="container-main">
    <div class="title-vdo-01">Frequently Asked Questions(FAQ)</div>

    <div class="abt-us">
      <p>
        <b style="font-weight: bold; font-size: 22px;">1. What is XOSHPLAY? </b
        ><br />
        <span style="text-align: justify;">
          Xoshplay is a Games store which offers unlimited access to instantly
          playable games categorized as Action, Adventure, Arcade, Family,
          Match, Puzzle, Racing, Sports through mobile internet connection.
        </span>
      </p>
      <p>
        <b style="font-weight: bold; font-size: 22px;"
          >2. How do I subscribe/register Xoshplay?</b
        ><br />
        <span style="text-align: justify;">
          Ans: visit
          <a
            href="http://xoshplay.com"
            style="text-decoration: none;color: white;"
            >http://xoshplay.com</a
          >
          and you need to register with your Robi/Airtel mobile number and buy
          coins to play games.
        </span>
      </p>
      <div>
        <b style="font-weight: bold; font-size: 22px;"
          >3. What is the Price Plan?</b
        ><br />
        <div style="text-align: justify;">
          <P
            >Ans: Each game needs to pay certain amount of coins to play. You
            will have to use your mobile balance to buy coins. On other hand few
            games are available for free trial as well.
          </P>
          <table>
            <caption>
              Robi User : One Time Package
            </caption>
            <tr>
              <th style="font-weight: bold;">SL</th>
              <th style="font-weight: bold;">Package</th>
              <th style="font-weight: bold;">Price(BDT)<br />(VAT+SD+SC)</th>
              <th style="font-weight: bold;">Validity<br />(Days)</th>
            </tr>
            <tr>
              <td>1</td>
              <td>ROOKIE</td>
              <td>2.67</td>
              <td rowspan="2">Unlimited</td>
            </tr>
            <tr>
              <td>2</td>
              <td>NOVICE</td>
              <td>6.66</td>
            </tr>
          </table>

          <table>
            <caption>
              GP user : One Time Package
            </caption>
            <tr>
              <th style="font-weight: bold;">SL</th>
              <th style="font-weight: bold;">Package</th>
              <th style="font-weight: bold;">Price(BDT)<br />(VAT+SD+SC)</th>
              <th style="font-weight: bold;">Validity<br />(Days)</th>
            </tr>
            <tr>
              <td>1</td>
              <td>ROOKIE</td>
              <td>2.67</td>
              <td rowspan="6" style="text-align: center;">Unlimited</td>
            </tr>
            <tr>
              <td>2</td>
              <td>NOVICE</td>
              <td>13.33</td>
            </tr>
            <tr>
              <td>3</td>
              <td>PRO</td>
              <td>26.65</td>
            </tr>
            <tr>
              <td>4</td>
              <td>MASTER</td>
              <td>39.98</td>
            </tr>
            <tr>
              <td>5</td>
              <td>GRANDMASTER</td>
              <td>53.33</td>
            </tr>
            <tr>
              <td>6</td>
              <td>PRIME</td>
              <td>66.63</td>
            </tr>
          </table>

          <br />
          <table>
            <caption>
              GP user : Subscription Package
            </caption>
            <tr>
              <th style="font-weight: bold;">SL</th>
              <th style="font-weight: bold;">Package</th>
              <th style="font-weight: bold;">Price(BDT)<br />(VAT+SD+SC)</th>
              <th style="font-weight: bold;">Validity<br />(Days)</th>
            </tr>
            <tr>
              <td>7</td>
              <td>DAILY</td>
              <td>2.67</td>
              <td>1</td>
            </tr>
            <tr>
              <td>8</td>
              <td>Weekly</td>
              <td>13.33</td>
              <td>7</td>
            </tr>
            <tr>
              <td>9</td>
              <td>Monthly</td>
              <td>39.98</td>
              <td>30</td>
            </tr>
          </table>
        </div>
      </div>
      <p>
        <b style="font-weight: bold; font-size: 22px;"
          >4. How do I Play games?</b
        ><br />
        <span style="text-align: justify;">
          Ans: After successful registration through mobile number you need to
          buy coins from Buy Coins section. Coins validity is unlimited & every
          games need 50 coins to play. After successful buying base upon your
          coins you can play each and every from the store
        </span>
      </p>
      <div>
        <b style="font-weight: bold; font-size: 22px;"
          >5. Is there any compatible mobile handset need to play Xoshplay?</b
        ><br />
        <p style="text-align: justify;">
          Ans: Xosplay portal can use by visiting
          <a
            href="http://xoshplay.com"
            style="text-decoration: none;color: white;"
            >http://xoshplay.com</a
          >
          through any mobile internet usability handsets.
        </p>
      </div>
      <p>
        <b style="font-weight: bold; font-size: 22px;"
          >6. Can I play offline? </b
        ><br />
        <span style="text-align: justify;">
          Ans: Portal games are HTML5 games and you need to be online to play
          games.
        </span>
      </p>
      <p>
        <b style="font-weight: bold; font-size: 22px;"
          >7. Why can’t I download game?</b
        ><br />
        <span style="text-align: justify;">
          Ans: As per Xoshplay modality games are HTML5 games and you have to
          play through xoshplay portal.
        </span>
      </p>
      <p>
        <b style="font-weight: bold; font-size: 22px;"
          >8. When I do not have coin can I still play the games on app /
          site?</b
        ><br />
        <span style="text-align: justify;">
          Ans: No, as it is a pay as you go based service, coins must need to
          play games
        </span>
      </p>
      <p>
        <b style="font-weight: bold; font-size: 22px;"
          >9. How do I unsubscribe/deregister from Xoshplay?</b
        ><br />
        <span style="text-align: justify;">
          Ans: As per modality this is a pay as you go service upon free
          successful portal registration. If user wants to deactivate his
          profile from the portal, ‘delete profile’ button is available in the
          profile section. With deactivation, all coins and purchase information
          Will be deleted permanently from the system
        </span>
      </p>
      <p>
        <b style="font-weight: bold; font-size: 22px;"
          >10. If I face any problem or I have query then whom do I contact? </b
        ><br />
        <span style="text-align: justify;">
          Ans: In such case for any help please use menu and select contact
          option then put relevant information and initiate send button. After
          successful delivery concern person review your relevant query/comments
          and they will feedback accordingly.
        </span>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Faq"
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
table,
th,
td {
  border: 1px solid black;
  border-collapse: collapse;
}

th,
td {
  padding: 5px;
  text-align: center;
}
</style>
