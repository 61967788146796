var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-main"},[_c('div',{staticClass:"title-vdo-01"},[_vm._v("Frequently Asked Questions(FAQ)")]),_c('div',{staticClass:"abt-us"},[_vm._m(0),_vm._m(1),_c('div',[_c('b',{staticStyle:{"font-weight":"bold","font-size":"22px"}},[_vm._v("3. What is the Price Plan?")]),_c('br'),_c('div',{staticStyle:{"text-align":"justify"}},[_c('P',[_vm._v("Ans: Each game needs to pay certain amount of coins to play. You will have to use your mobile balance to buy coins. On other hand few games are available for free trial as well. ")]),_vm._m(2),_vm._m(3),_c('br'),_vm._m(4)],1)]),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10),_vm._m(11)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('b',{staticStyle:{"font-weight":"bold","font-size":"22px"}},[_vm._v("1. What is XOSHPLAY? ")]),_c('br'),_c('span',{staticStyle:{"text-align":"justify"}},[_vm._v(" Xoshplay is a Games store which offers unlimited access to instantly playable games categorized as Action, Adventure, Arcade, Family, Match, Puzzle, Racing, Sports through mobile internet connection. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('b',{staticStyle:{"font-weight":"bold","font-size":"22px"}},[_vm._v("2. How do I subscribe/register Xoshplay?")]),_c('br'),_c('span',{staticStyle:{"text-align":"justify"}},[_vm._v(" Ans: visit "),_c('a',{staticStyle:{"text-decoration":"none","color":"white"},attrs:{"href":"http://xoshplay.com"}},[_vm._v("http://xoshplay.com")]),_vm._v(" and you need to register with your Robi/Airtel mobile number and buy coins to play games. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('table',[_c('caption',[_vm._v(" Robi User : One Time Package ")]),_c('tr',[_c('th',{staticStyle:{"font-weight":"bold"}},[_vm._v("SL")]),_c('th',{staticStyle:{"font-weight":"bold"}},[_vm._v("Package")]),_c('th',{staticStyle:{"font-weight":"bold"}},[_vm._v("Price(BDT)"),_c('br'),_vm._v("(VAT+SD+SC)")]),_c('th',{staticStyle:{"font-weight":"bold"}},[_vm._v("Validity"),_c('br'),_vm._v("(Days)")])]),_c('tr',[_c('td',[_vm._v("1")]),_c('td',[_vm._v("ROOKIE")]),_c('td',[_vm._v("2.67")]),_c('td',{attrs:{"rowspan":"2"}},[_vm._v("Unlimited")])]),_c('tr',[_c('td',[_vm._v("2")]),_c('td',[_vm._v("NOVICE")]),_c('td',[_vm._v("6.66")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('table',[_c('caption',[_vm._v(" GP user : One Time Package ")]),_c('tr',[_c('th',{staticStyle:{"font-weight":"bold"}},[_vm._v("SL")]),_c('th',{staticStyle:{"font-weight":"bold"}},[_vm._v("Package")]),_c('th',{staticStyle:{"font-weight":"bold"}},[_vm._v("Price(BDT)"),_c('br'),_vm._v("(VAT+SD+SC)")]),_c('th',{staticStyle:{"font-weight":"bold"}},[_vm._v("Validity"),_c('br'),_vm._v("(Days)")])]),_c('tr',[_c('td',[_vm._v("1")]),_c('td',[_vm._v("ROOKIE")]),_c('td',[_vm._v("2.67")]),_c('td',{staticStyle:{"text-align":"center"},attrs:{"rowspan":"6"}},[_vm._v("Unlimited")])]),_c('tr',[_c('td',[_vm._v("2")]),_c('td',[_vm._v("NOVICE")]),_c('td',[_vm._v("13.33")])]),_c('tr',[_c('td',[_vm._v("3")]),_c('td',[_vm._v("PRO")]),_c('td',[_vm._v("26.65")])]),_c('tr',[_c('td',[_vm._v("4")]),_c('td',[_vm._v("MASTER")]),_c('td',[_vm._v("39.98")])]),_c('tr',[_c('td',[_vm._v("5")]),_c('td',[_vm._v("GRANDMASTER")]),_c('td',[_vm._v("53.33")])]),_c('tr',[_c('td',[_vm._v("6")]),_c('td',[_vm._v("PRIME")]),_c('td',[_vm._v("66.63")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('table',[_c('caption',[_vm._v(" GP user : Subscription Package ")]),_c('tr',[_c('th',{staticStyle:{"font-weight":"bold"}},[_vm._v("SL")]),_c('th',{staticStyle:{"font-weight":"bold"}},[_vm._v("Package")]),_c('th',{staticStyle:{"font-weight":"bold"}},[_vm._v("Price(BDT)"),_c('br'),_vm._v("(VAT+SD+SC)")]),_c('th',{staticStyle:{"font-weight":"bold"}},[_vm._v("Validity"),_c('br'),_vm._v("(Days)")])]),_c('tr',[_c('td',[_vm._v("7")]),_c('td',[_vm._v("DAILY")]),_c('td',[_vm._v("2.67")]),_c('td',[_vm._v("1")])]),_c('tr',[_c('td',[_vm._v("8")]),_c('td',[_vm._v("Weekly")]),_c('td',[_vm._v("13.33")]),_c('td',[_vm._v("7")])]),_c('tr',[_c('td',[_vm._v("9")]),_c('td',[_vm._v("Monthly")]),_c('td',[_vm._v("39.98")]),_c('td',[_vm._v("30")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('b',{staticStyle:{"font-weight":"bold","font-size":"22px"}},[_vm._v("4. How do I Play games?")]),_c('br'),_c('span',{staticStyle:{"text-align":"justify"}},[_vm._v(" Ans: After successful registration through mobile number you need to buy coins from Buy Coins section. Coins validity is unlimited & every games need 50 coins to play. After successful buying base upon your coins you can play each and every from the store ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b',{staticStyle:{"font-weight":"bold","font-size":"22px"}},[_vm._v("5. Is there any compatible mobile handset need to play Xoshplay?")]),_c('br'),_c('p',{staticStyle:{"text-align":"justify"}},[_vm._v(" Ans: Xosplay portal can use by visiting "),_c('a',{staticStyle:{"text-decoration":"none","color":"white"},attrs:{"href":"http://xoshplay.com"}},[_vm._v("http://xoshplay.com")]),_vm._v(" through any mobile internet usability handsets. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('b',{staticStyle:{"font-weight":"bold","font-size":"22px"}},[_vm._v("6. Can I play offline? ")]),_c('br'),_c('span',{staticStyle:{"text-align":"justify"}},[_vm._v(" Ans: Portal games are HTML5 games and you need to be online to play games. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('b',{staticStyle:{"font-weight":"bold","font-size":"22px"}},[_vm._v("7. Why can’t I download game?")]),_c('br'),_c('span',{staticStyle:{"text-align":"justify"}},[_vm._v(" Ans: As per Xoshplay modality games are HTML5 games and you have to play through xoshplay portal. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('b',{staticStyle:{"font-weight":"bold","font-size":"22px"}},[_vm._v("8. When I do not have coin can I still play the games on app / site?")]),_c('br'),_c('span',{staticStyle:{"text-align":"justify"}},[_vm._v(" Ans: No, as it is a pay as you go based service, coins must need to play games ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('b',{staticStyle:{"font-weight":"bold","font-size":"22px"}},[_vm._v("9. How do I unsubscribe/deregister from Xoshplay?")]),_c('br'),_c('span',{staticStyle:{"text-align":"justify"}},[_vm._v(" Ans: As per modality this is a pay as you go service upon free successful portal registration. If user wants to deactivate his profile from the portal, ‘delete profile’ button is available in the profile section. With deactivation, all coins and purchase information Will be deleted permanently from the system ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('b',{staticStyle:{"font-weight":"bold","font-size":"22px"}},[_vm._v("10. If I face any problem or I have query then whom do I contact? ")]),_c('br'),_c('span',{staticStyle:{"text-align":"justify"}},[_vm._v(" Ans: In such case for any help please use menu and select contact option then put relevant information and initiate send button. After successful delivery concern person review your relevant query/comments and they will feedback accordingly. ")])])
}]

export { render, staticRenderFns }